import React from "react";

import Layout from "../components/layout";

const IndexPage = () => (
  <Layout className="mvh-100 flex-ns ph3 items-center justify-center">
    <div className="w-third-ns flex items-center pa3 pa0-ns">
      <img
        src={require("../images/ratm-poster.jpg")}
        alt="Poster"
        className="db shadow-5"
      />
    </div>
    <div className="flex flex-column">
      <h1 className="dn db-ns f1-l f2-m tc dymo rotate-1 mb5-l">
        <span className="bg-white dib rotate-1">RAGE AGAINST</span>
        <br />
        <span className="bg-white dib">THE MAINSTREAM</span>
      </h1>

      <div className="flex-ns items-center justify-center mb3 mb5-ns">
        <div className="mr3-ns" />
        <div className="f4 f4-ns lh-copy measure ph3">
          <p>Hej med dig.</p>
          <p>
            Her kan du købe vores show Rage Against The Mainstream. Showet var
            landet rundt i vinteren 2018, og nu kan du se det derhjemme imens du
            spiser gifler. Vildt ikke?! For kun 50 slask kan du downloade 2
            timers stand-up, der berører alle de emner der gør os aller mest
            rasende. Det er semiprovokerende satire i højt tempo, så hvis du
            synes at alt i Danmark er helt perfekt, skal du nok holde dig væk….
            Men det gør du jo ikke. Vi håber du nyder showet. Vi har glædet os
            til at vise dig det!
          </p>

          <p>
            Venlig Hilsen Michael Schøt, Torben Chris, Martin Nørgaard og Morten
            Wichmann.
          </p>
        </div>
      </div>

      <div className="tc pb4 ph3">
        <p>
          <a
            href="https://funnn.co/ratm/ratm"
            className="dymo-rev btn bg-ratm-maroon white w-100 w-auto-ns f3 f2-ns ph4-m ph5-l pv3-l pv2 shadow-2"
            data-funnn
          >
            Køb for 50,-
          </a>
        </p>
        <p>
          <a
            href="https://funnn.co/users/sign_in"
            className="link underline black"
          >
            Gendan køb
          </a>
        </p>
      </div>
    </div>
    <script src="https://funnn.co/embed.js" />
  </Layout>
);

export default IndexPage;
